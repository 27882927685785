import { Component, OnInit, Input, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { Plan } from 'src/app/models/product/plan.model';
import { combineLatest } from 'rxjs';
import { Series } from 'src/app/models/product/series.model';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { QMI } from 'src/app/models/product/qmi.model';
import { ProductOverviewHometype, ProductOverviewModel } from 'src/app/models/content/editorial/product-overview.model';
import { PlanCardModel } from 'src/app/models/content/plan/plan-card.model';
import { QmiCardModel } from 'src/app/models/content/qmi/qmi-card.model';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { HersScoreService } from 'src/app/core-services/hers-score.service';
import { BaseComponent } from '../../shared/base/base.component';
import { LmpService } from 'src/app/core-services/lmp.service';
import { ProductOverviewService } from 'src/app/core-services/product-overview.service';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { ProductFilterService } from 'src/app/core-services/product-filter.service';
import { fadeInDownOnEnterAnimation, fadeInUpOnEnterAnimation, fadeOutDownOnLeaveAnimation } from 'angular-animations';
import { createStatusOverlayText } from 'src/helper-functions/plan-data-helper';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss'],
  animations: [
    fadeInUpOnEnterAnimation(),
    fadeInDownOnEnterAnimation(),
    fadeOutDownOnLeaveAnimation()
  ]
})
export class ProductOverviewComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: ProductOverviewModel;

  plans: Plan[] = [];
  series: Series[] = [];
  qmis: QMI[] = [];

  planCardModel: PlanCardModel;
  qmiCardModel: QmiCardModel;

  showSeriesOverview: boolean = true;
  activeSeriesNav: string;
  filteredPlans: Plan[] = [];
  filteredQmis: QMI[] = [];
  closeFilteredPlans: Plan[] = [];
  closeFilteredQmis: QMI[] = [];
  selectedSeries: Series;
  selectedHomeType: string = ProductOverviewHometype.Homes; // 'Homes'

  private showAdditionalFilters: boolean = false;

  constructor(private communityService: CommunityService,
    private productOverviewService: ProductOverviewService,
    private hersScoreService: HersScoreService,
    private lmpService: LmpService,
    private productFilterService: ProductFilterService,
    private adobeLaunchService: AdobeLaunchService,
    private cdr: ChangeDetectorRef) { super(); }

  ngOnInit() {
    var cmsPIOnlyFlag: boolean = false;
    var overrideBrandPIOnlyFlag: boolean = false;
    var sitecorePIOnlyFlag: boolean = false;

    const observables = [this.communityService.plans,
    this.communityService.community,
    this.communityService.series,
    this.communityService.qmis,
    this.hersScoreService.HersScoreitem,
    this.lmpService.LmpConfiguration];

    combineLatest(observables)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([plans, community, series, qmis, hers, lmp]: any[]) => {
        overrideBrandPIOnlyFlag = community?.OverrideBrandDisplayPIOnlyFlag;
        cmsPIOnlyFlag = community?.DisplayPIOnlyFlag;
        // Plans
        if (plans && plans.length) {
          this.planCardModel = this.mapSitecoreLabelsToPlanCard(this.data);
          if (lmp) {
            this.planCardModel.LmpConfiguration = lmp;
            sitecorePIOnlyFlag = lmp.Display_PI_Only;
          }
          this.plans = this.filteredPlans = plans;
          this.plans.map(plan => {
            if (plan.Images && plan.Images.length) {
              plan.Images = plan.Images.filter(image => image.ImageRank > 0).sort((a, b) => a.ImageRank - b.ImageRank);
            }
            plan.DisplayPIOnly = overrideBrandPIOnlyFlag ? cmsPIOnlyFlag : sitecorePIOnlyFlag;
            plan.OverlayText = createStatusOverlayText(plan, this.planCardModel)
          });
          if (hers) {
            this.planCardModel.HersScore = hers;
          }
        } else {
          this.selectedHomeType = ProductOverviewHometype.Qmis;
        }
        // QMIs
        if (qmis && qmis.length) {
          this.qmiCardModel = this.mapSitecoreLabelsToQmiCard(this.data);
          if (lmp) {
            this.qmiCardModel.LmpConfiguration = lmp;
            sitecorePIOnlyFlag = lmp.Display_PI_Only;
          }
          this.qmis = this.filteredQmis = qmis;
          this.qmis.map(qmi => {
            if (qmi.Images && qmi.Images.length) {
              qmi.Images = qmi.Images.filter(image => image.ImageRank > 0).sort((a, b) => a.ImageRank - b.ImageRank);
            }
            qmi.DisplayPIOnly = overrideBrandPIOnlyFlag ? cmsPIOnlyFlag : sitecorePIOnlyFlag;
          });
        }
        // Component Data
        if (this.data.QMI_Default_Toggle) {
          this.activeSeriesNav = 'All';
          this.selectedHomeType = ProductOverviewHometype.Qmis;
          this.showSeriesOverview = false;
        }
        // Series
        if (series) {
          this.series = series;
          if (!this.series || !this.series.length) {
            this.showSeriesOverview = false;
            this.activeSeriesNav = 'All';
            this.setOverviewParams();
          }
        }
      });

    // Product Overview Service Data Subjects
    const productOverviewObs = [this.productOverviewService.selectedHomeType,
    this.productOverviewService.seriesID];
    combineLatest(productOverviewObs)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([homeType, seriesID]: any[]) => {
        if (homeType) {
          this.selectedHomeType = homeType;
        }
        if (seriesID) {
          this.goToSeriesDetails(seriesID);
        }
      });
  }

  ngAfterViewInit() {
    // Product Filter Service Data Subjects
    this.productFilterService.closeHomes
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(withLatestFrom(this.productFilterService.exactHomes))
      .subscribe(([closeHomes, exactHomes]: any[]) => {
        if (exactHomes) {
          if (this.isHomesTypeSelected()) {
            this.filteredPlans = exactHomes;
          } else {
            this.filteredQmis = exactHomes;
          }
        } else {
          this.goToSeriesDetails(this.activeSeriesNav);
          this.setOverviewParams();
        }
        if (closeHomes) {
          if (this.isHomesTypeSelected()) {
            this.closeFilteredPlans = closeHomes;
          } else {
            this.closeFilteredQmis = closeHomes;
          }
        } else {
          this.closeFilteredPlans = null;
          this.closeFilteredQmis = null;
        }
        this.pushAdobeLaunchPlanListingEvent();
        this.cdr.detectChanges();
      });
  }

  filterHomesBySeriesId(seriesId: string): void {
    if (seriesId) {
      this.activeSeriesNav = seriesId;
      if (this.activeSeriesNav === 'All') {
        this.selectedSeries = null;
        this.filteredPlans = this.plans;
        this.filteredQmis = this.qmis;
      } else {
        this.selectedSeries = this.series.find(x => x.SeriesID === this.activeSeriesNav);
        this.filteredPlans = this.plans.filter(x => x.SeriesName === this.selectedSeries.SeriesName);
        this.filteredQmis = this.qmis.filter(x => x.Plan.SeriesName === this.selectedSeries.SeriesName);
      }
    } else {
      this.filteredPlans = [];
      this.filteredQmis = [];
    }
  }

  toggleHomeTypeFromNav(event: NgbNavChangeEvent): void {
    const selectedHomeType: string = event.nextId;
    this.selectedHomeType = selectedHomeType;
  }

  toggleHomeTypeFromSwitch(): void {
    if (this.isHomesTypeSelected()) {
      this.selectedHomeType = ProductOverviewHometype.Qmis;
    } else {
      this.selectedHomeType = ProductOverviewHometype.Homes;
    }
    this.setOverviewParams();
  }

  isHomesTypeSelected() {
    return this.selectedHomeType === ProductOverviewHometype.Homes;
  }

  get toggledHomeType(): string {
    if (this.isHomesTypeSelected()) {
      return ProductOverviewHometype.Qmis;
    }
    return ProductOverviewHometype.Homes;
  }

  goToSeriesDetails(seriesId: string) {
    this.showSeriesOverview = false;
    this.filterHomesBySeriesId(seriesId);
  }

  // Trackby function for Plan and QMI cards rendered within ngFor loops in the template
  // to allow Angular to track which cards specifically are being added or removed from
  // both "exact" and "close" matched filter arrays. Was needed to allow the cards to hook into
  // their OnChange methods for analytics purposes. This also has the added benefit of
  // being a performance enhancement for the various ngFor loops we're doing in the DOM.
  // https://jinalshah999.medium.com/trackby-with-ngfor-directives-in-angular-application-bd4d0db288eb
  cardTrackBy(item) {
    return item.id;
  }

  private mapSitecoreLabelsToPlanCard(sitecoreLabels: ProductOverviewModel): PlanCardModel {
    const planCardModel = new PlanCardModel();
    if (sitecoreLabels) {
      planCardModel.Series_Name = sitecoreLabels.Series_Name;
      planCardModel.Price = sitecoreLabels.Plan_Price;
      planCardModel.Bedrooms = sitecoreLabels.Plan_Bedrooms;
      planCardModel.Bathrooms = sitecoreLabels.Plan_Bathrooms;
      planCardModel.Garage = sitecoreLabels.Plan_Garage;
      planCardModel.Square_Feet = sitecoreLabels.Plan_Square_Feet;
      planCardModel.CTA_Page_Item_Ref = sitecoreLabels.Plan_CTA_Page_Item_Ref;
      planCardModel.CTA_Detail = sitecoreLabels.Plan_CTA_Detail;
      planCardModel.LMP_Starting_From = sitecoreLabels.Plan_LMP_Starting_From;
      planCardModel.PITI_Price_Label = sitecoreLabels.PlanPitiPriceLabel;
      planCardModel.QMI_Availability = sitecoreLabels.QMI_Availability;
      planCardModel.QMI_Only = sitecoreLabels.Plan_QMI_Only;
      planCardModel.Almost_Sold_Out = sitecoreLabels.Plan_Almost_Sold_Out;
      planCardModel.Future_Release = sitecoreLabels.Plan_Future_Release;
      planCardModel.Last_Chance = sitecoreLabels.Plan_Last_Chance;
      planCardModel.Last_Home_Design = sitecoreLabels.Plan_Last_Home_Design;
      planCardModel.Model_For_Sale = sitecoreLabels.Plan_Model_For_Sale;
      planCardModel.Model_Open = sitecoreLabels.Plan_Model_Open;
      planCardModel.New_Model = sitecoreLabels.Plan_New_Model;
      planCardModel.New_Plan = sitecoreLabels.Plan_New_Plan;
      planCardModel.Sold_Out = sitecoreLabels.Plan_Sold_Out;
      planCardModel.Call_For_Pricing_Text = sitecoreLabels.Call_For_Pricing_Text;
    }
    return planCardModel;
  }

  private mapSitecoreLabelsToQmiCard(sitecoreLabels: ProductOverviewModel): QmiCardModel {
    const qmiCardModel = new QmiCardModel();
    if (sitecoreLabels) {
      qmiCardModel.Series_Name = sitecoreLabels.Series_Name;
      qmiCardModel.Price = sitecoreLabels.QMI_Price;
      qmiCardModel.Bedrooms = sitecoreLabels.QMI_Bedrooms;
      qmiCardModel.Bathrooms = sitecoreLabels.QMI_Bathrooms;
      qmiCardModel.Garage = sitecoreLabels.QMI_Garage;
      qmiCardModel.Square_Feet = sitecoreLabels.QMI_Square_Feet;
      qmiCardModel.Lot = sitecoreLabels.QMI_Lot;
      qmiCardModel.QMI_CTA_Page_Item_Ref = sitecoreLabels.QMI_CTA_Page_Item_Ref;
      qmiCardModel.QMI_CTA_Text = sitecoreLabels.QMI_CTA_Detail;
      qmiCardModel.Price_Discount = sitecoreLabels.QMI_Price_Discount;
      qmiCardModel.Availability_Date = sitecoreLabels.QMI_Availability_Date;
      qmiCardModel.Call_For_Pricing_Text = sitecoreLabels.Call_For_Pricing_Text;
      qmiCardModel.LMPStartingFrom = sitecoreLabels.QMILMPStartingFrom;
      qmiCardModel.PITIPriceLabel = sitecoreLabels.QMIPitiPriceLabel;
    }

    return qmiCardModel;
  }

  private setOverviewParams() {
    this.productOverviewService.updateSelectedHomeType(this.selectedHomeType);
    this.productOverviewService.updateSeriesID(this.activeSeriesNav);
  }

  // Adobe Launch Listing Analytics
  private pushAdobeLaunchPlanListingEvent() {
    if (this.isHomesTypeSelected()) {
      const plans = this.filteredPlans.concat(this.closeFilteredPlans);
      if (plans && plans.length) {
        this.adobeLaunchService.pushPlanListingDisplayedEvent(plans);
      }
    } else {
      const qmis = this.filteredQmis.concat(this.closeFilteredQmis);
      if (qmis && qmis.length) {
        this.adobeLaunchService.pushQMIListingDisplayedEvent(qmis);
      }
    }
  }
}
