import { Component, OnInit, Input } from '@angular/core';
import { Plan } from 'src/app/models/product/plan.model';
import { PlanCardModel } from 'src/app/models/content/plan/plan-card.model';
import { ModalClasses } from 'src/app/shared/modal-classes';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HersScoreModalComponent } from '../../shared/modals/hers-score-modal/hers-score-modal.component';
import { VideoModalModel } from 'src/app/models/content/media/video-modal.model';
import { PlanVirtualTourModalComponent } from '../../shared/modals/plan-virtual-tour-modal/plan-virtual-tour-modal.component';
import { VideoModalComponent } from '../../shared/modals/video-modal/video-modal.component';
import { AdobeLaunchService } from 'src/app/core-services/adobe-launch.service';
import { createStatusOverlayText } from 'src/helper-functions/plan-data-helper';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss']
})
export class PlanCardComponent implements OnInit {

  @Input() data: PlanCardModel;
  @Input() plan: Plan;
  @Input() isTourCard: boolean;

  hasVideos: boolean;

  constructor(private modalService: NgbModal, private adobeLaunchService: AdobeLaunchService) { }

  ngOnInit() {
    if (this.plan && this.plan.Videos && this.plan.Videos.length && this.plan.Videos[0].VideoPath) {
      this.hasVideos = true;
    }
  }

  openHersModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(HersScoreModalComponent,
      { windowClass: `${ModalClasses.Full}`, backdrop: false });
    modalRef.componentInstance.HersScore = this.data.HersScore;
    modalRef.componentInstance.plan = this.plan;
  }

  openVirtualTourModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(PlanVirtualTourModalComponent, { backdrop: false, windowClass: 'modal--full' });
    const videoModalModel: VideoModalModel = new VideoModalModel();
    videoModalModel.ModalHeader = this.data.Virtual_Tour_Modal_Header_Text;
    videoModalModel.VirtualTourUrl = this.plan.VirtualTour;
    modalRef.componentInstance.videoModalModel = videoModalModel;
  }

  openVideoTourModal(): void {
    const modalRef: NgbModalRef = this.modalService.open(VideoModalComponent, { backdrop: false, windowClass: 'modal--full' });
    const videoModalModel: VideoModalModel = new VideoModalModel();
    if (this.hasVideos) {
      videoModalModel.ModalHeader = this.data.Video_Tour_Modal_Header_Text;
      videoModalModel.ModalDescriptionPlacement = this.data.Video_Tour_Modal_Caption_Placement;
      videoModalModel.VideoId = this.plan.Videos[0].VideoPath;
      if (this.plan.Videos[0].Caption) {
        videoModalModel.ModalDescription = this.plan.Videos[0].Caption;
      }
    }
    modalRef.componentInstance.videoModalModel = videoModalModel;
  }

  handlePlanClicked(plan: Plan) {
    if (plan) {
      this.adobeLaunchService.pushPlanListingClickedEvent(plan, plan.Price);
    }
  }
}
