// this file contains functions used on plan components

import { Plan } from 'src/app/models/product/plan.model';

export function createStatusOverlayText(plan: Plan, data: any) {
  if (plan && data) {
    const overlayLabels = [];
    if (plan.InventoryCount > 0 && data.QMI_Availability) {
      overlayLabels.push(data.QMI_Availability);
    }
    if (plan.PlanFlagOne) {
      overlayLabels.push(plan.PlanFlagOne);
    }
    if (plan.PlanFlagTwo && overlayLabels.length < 2) {
      overlayLabels.push(plan.PlanFlagTwo);
    }
    return overlayLabels.length > 0 ? overlayLabels : undefined;
  }
  return undefined;
}
