import { Component, OnInit, Input } from '@angular/core';
import { CommunityService } from 'src/app/core-services/community.service';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil, concatMap } from 'rxjs/operators';
import { Plan } from 'src/app/models/product/plan.model';
import { PlanGalleryModel } from 'src/app/models/content/plan/plan-gallery.model';
import { GalleryModalModel } from 'src/app/models/content/media/gallery-modal.model';
import { BaseComponent } from '../../shared/base/base.component';
import { createStatusOverlayText } from 'src/helper-functions/plan-data-helper';

@Component({
  selector: 'app-plan-gallery',
  templateUrl: './plan-gallery.component.html',
  styleUrls: ['./plan-gallery.component.scss']
})
export class PlanGalleryComponent extends BaseComponent implements OnInit {

  @Input() data: PlanGalleryModel;
  @Input() statusOverlayText: string[];

  plan: Plan;
  hasActiveQmi: boolean = false;
  galleryModalModel: GalleryModalModel;

  constructor(private communityService: CommunityService,
              private route: ActivatedRoute) { super(); }

    ngOnInit() {
      this.route.params.pipe(
        map(routeParam => routeParam.id),
        concatMap((planId: string) => {
          return this.communityService.getPlanById(planId);
        }),
        takeUntil(this.unsubscribe$))
        .subscribe((plan: Plan) => {
          this.plan = plan;
          this.statusOverlayText = createStatusOverlayText(this.plan, this.data);
        });

      this.galleryModalModel = new GalleryModalModel();
      this.galleryModalModel.ModalTitle = this.data.Modal_Title;
      this.galleryModalModel.ExteriorLabel = this.data.Exterior_Label;
      this.galleryModalModel.InteriorLabel = this.data.Interior_Label;
      this.galleryModalModel.IsFilterGallery = true;
  }
}
